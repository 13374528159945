<template>
  <van-popup
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
    :style="{ width: '100%', maxHeight: '80%', minHeight:'35%', background: '#1E232D', padding: '0 10px' }"  
    :transition-appear=true
    position="bottom" 
    duration="0.5"
    @input="val => this.$emit('input', val)"
    @close='closePop'
    round
  >
    <div class="title">
      <div class="title-left">{{ title }}</div>
      <div class="title-right" @click="handleConfirm"> {{ $t('common.sure') }} </div>
    </div>
    <div class="pop-body">
     <!-- 标签组 -->
     <div class="label-groups">
        <div class="item" 
          v-for="(item,index) in labels" :key="index" 
          :class="{'selected': labelItems[item]}"
          @click="select(item)"
        >
          {{ item }}
        </div>
     </div>
    </div>

  </van-popup>

</template>

<script>
export default {
  name: '',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '个人标签'
    },
    popType: {
      type: Number,
      default: 1
    },
    labels: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      count: 0,
      labelItems: {}
    }
  },
  methods: {
    init() {},
    closePop() {
      this.count = 0
      this.labelItems = {}
      this.$emit('input', false)
    },
    handleConfirm() {
      this.$emit('getSelectedList', this.getSelected())
      this.closePop()
    },
    select(name) {
      if(this.labelItems[name] === undefined || !this.labelItems[name]) {
        if(this.count >= this.limit) {
          this.$toast(this.$t('agent.mostSelect')+this.limit+ this.$t('agent.unit'))
          return
        }
        this.labelItems[name] = true
        this.count++
      } else {
        this.labelItems[name] = false
        this.count--
      }
      this.$forceUpdate()
    },
    getSelected() {
      let finalList = []
      for(let key in this.labelItems) {
        if(this.labelItems[key]) {
          finalList.push(key)
        }
      }
      return finalList
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>

.title {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 40px;
  .title-left {
    font-weight: bold;
    color: #fff;
  }
  .title-right {
    color: #FD2084;
  }
}

.selected { // 选中时，背景色（暂定）
  // background: linear-gradient(151deg, #F00CD9 0%, #A105DE 67%);
  background: rgba(253, 32, 132, 0.3);
  color:#FD2084;
  border: none !important;
}

.pop-body {
  padding-bottom: 50px;
  .label-box {
    border: 1px solid gray;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;

    .label-item {
      width: 26%;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      border-radius: 20px;
      font-size: 16px;
    }
  }

  .label-groups {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 26%;
      height: 40px;
      border: 1px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      border-radius: 20px;
      font-size: 16px;
      opacity: 0.6;
    }
    
  }

  .btn-groups {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    .btn {
      width: 45%;
      height: 35px;
    }
  }

}
.van-popup--round {
  border-radius: 5px 5px 0 0;
}

</style>